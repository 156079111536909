import resume from "../assets/pdf/ManviJain_Resume.pdf";
import First from "../assets/png/first.png";

export const headerData = {
  name: "Manvi Jain",
  title: "Full Stack Developer",
  desciption: "Open Source Enthusiast🔥",
  smallDesciption:
    " A passionate individual who always thrive to work on end to end products which develop sustainable and scalable technical systems to create impact. ",
  image: First,
  resumePdf: resume,
};
