export const experienceData = [
  {
    id: 1,
    company: "Antino Labs",
    jobtitle: "Frontend Developer Intern",
    startYear: "December 2021",
    endYear: "Present",
  },
  {
    id: 2,
    company: "timelyAI",
    jobtitle: "Full Stack Developer Intern",
    startYear: "November 2021",
    endYear: "December 2021",
  },
  {
    id: 3,
    company: "Design Noise",
    jobtitle: "UI/UX and Graphic Designer Intern",
    startYear: "April 2021",
    endYear: "July 2021",
  },
  {
    id: 4,
    company: "Digital Projekt",
    jobtitle: "Front-end Development Engineer Intern",
    startYear: "June 2020",
    endYear: "December 2020",
  },
];
