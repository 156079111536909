import four from "../assets/svg/projects/four.svg";
import one from "../assets/svg/projects/one.svg";
import three from "../assets/svg/projects/three.svg";
import two from "../assets/svg/projects/two.svg";

export const projectsData = [
  {
    id: 1,
    projectName: "D&D - Make it happen!",
    projectDesc:
      "Created a platform for a Designer and a Developer to collaborate and showcase their work.",
    tags: ["React JS", "Tailwind CSS", "MongoDB"],
    code: "https://github.com/The-Major-Project",
    demo: "https://designanddevelop.netlify.app/",
    image: one,
  },
  {
    id: 2,
    projectName: "MovEasy",
    projectDesc:
      "Developed a transportation system that allows users to transfer items and lets transporters get a fair rate by bidding on a post.",
    tags: ["React JS", "Tailwind CSS", "Firebase"],
    code: "https://github.com/MovEasy-org/MovEasy-Frontend",
    demo: "https://github.com/MovEasy-org/MovEasy-Frontend",
    image: two,
  },
  {
    id: 3,
    projectName: "Consultant Website",
    projectDesc:
      "Developd a website who has a  team of Architects, Project managers and Engineers, Interior and landscape designers",
    tags: [" jQuery", "Bootstrap. "],
    code: "https://github.com/manvijain08/LV-Consultants",
    demo: "http://lv-consultants.com/",
    image: four,
  },
  {
    id: 4,
    projectName: "Hustler Village",
    projectDesc:
      "Platform for collaborating of designer, developer or recruiter.",
    tags: ["React JS", "Sass"],
    code: "https://github.com/manvijain08/hustlers-village",
    demo: "https://6249d9e4eba34b6fd0625150--dreamy-heliotrope-d70d54.netlify.app/",
    image: three,
  },
];
