export const aboutData = {
  title: "Here's What I do",
  description1:
    "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications.",
  description2:
    "⚡ Building responsive website front end using ReactJS and NextJs.",
  description3:
    "⚡ Creating application backend in mongoDB, ExpressJs & mongoDB.",
  description4: "⚡ Integration of third party services such as Firebase/ AWS.",
  description5:
    "⚡ Designing highly attractive user interface for mobile and web applications",
  image: 2,
};
