import adobeXd from "../assets/svg/skills/adobe-xd.svg";
import bootstrap from "../assets/svg/skills/bootstrap.svg";
import canva from "../assets/svg/skills/canva.svg";
import css from "../assets/svg/skills/css.svg";
import figma from "../assets/svg/skills/figma.svg";
import firebase from "../assets/svg/skills/firebase.svg";
import git from "../assets/svg/skills/git.svg";
import html from "../assets/svg/skills/html.svg";
import illustrator from "../assets/svg/skills/illustrator.svg";
import javascript from "../assets/svg/skills/javascript.svg";
import materialui from "../assets/svg/skills/materialui.svg";
import mongoDB from "../assets/svg/skills/mongoDB.svg";
import mysql from "../assets/svg/skills/mysql.svg";
import nextJS from "../assets/svg/skills/nextJS.svg";
import nodejs from "../assets/svg/skills/nodejs.svg";
import photoshop from "../assets/svg/skills/photoshop.svg";
import postgresql from "../assets/svg/skills/postgresql.svg";
import pycharm from "../assets/svg/skills/pycharm.svg";
import python from "../assets/svg/skills/python.svg";
import react from "../assets/svg/skills/react.svg";
import tailwind from "../assets/svg/skills/tailwind.svg";
import typescript from "../assets/svg/skills/typescript.svg";
import vscode from "../assets/svg/skills/vscode.svg";

export const skillsImage = (skill) => {
  const skillID = skill.toLowerCase();
  switch (skillID) {
    case "html":
      return html;
    case "photoshop":
      return photoshop;
    case "illustrator":
      return illustrator;
    case "adobe xd":
      return adobeXd;
    case "css":
      return css;
    case "javascript":
      return javascript;
    case "next js":
      return nextJS;
    case "react":
      return react;
    case "typescript":
      return typescript;
    case "bootstrap":
      return bootstrap;
    case "mongodb":
      return mongoDB;
    case "mysql":
      return mysql;
    case "postgresql":
      return postgresql;
    case "tailwind":
      return tailwind;
    case "python":
      return python;
    case "firebase":
      return firebase;
    case "git":
      return git;
    case "materialui":
      return materialui;
    case "figma":
      return figma;
    case "canva":
      return canva;
    case "nodejs":
      return nodejs;
    case "vscode":
      return vscode;
    case "pycharm":
      return pycharm;
    default:
      break;
  }
};
