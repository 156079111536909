import React, { useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import "./Footer.css";

function Footer() {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="footer" style={{ backgroundColor: theme.secondary }}>
      <p style={{ color: theme.tertiary }}>
        <span style={{ color: "#E6491A" }}>Developed by </span>Manvi Jain
      </p>
    </div>
  );
}

export default Footer;
