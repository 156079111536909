import { makeStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";
import { FaGithub, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { FiAtSign } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import footer from "../../assets/svg/contacts/footer.svg";
import { ThemeContext } from "../../contexts/ThemeContext";
import { contactsData } from "../../data/contactsData";
import { socialsData } from "../../data/socialsData";
import "./Contacts.css";

function Contacts() {
  const { theme } = useContext(ThemeContext);
  const useStyles = makeStyles((t) => ({
    label: {
      backgroundColor: `${theme.secondary}`,
      color: `${theme.primary}`,
      fontFamily: "var(--primaryFont)",
      fontWeight: 600,
      fontSize: "0.9rem",
      padding: "0 5px",
      transform: "translate(25px,50%)",
      display: "inline-flex",
    },
    socialIcon: {
      width: "45px",
      height: "45px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "21px",
      backgroundColor: theme.primary,
      color: theme.secondary,
      transition: "250ms ease-in-out",
      "&:hover": {
        transform: "scale(1.1)",
        color: theme.secondary,
        backgroundColor: theme.tertiary,
      },
    },
    detailsIcon: {
      backgroundColor: theme.primary,
      color: theme.secondary,
      borderRadius: "50%",
      width: "45px",
      height: "45px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "23px",
      transition: "250ms ease-in-out",
      flexShrink: 0,
      "&:hover": {
        transform: "scale(1.1)",
        color: theme.secondary,
        backgroundColor: theme.tertiary,
      },
    },
  }));

  const classes = useStyles();

  return (
    <div
      className="contacts"
      id="contacts"
      style={{ backgroundColor: theme.secondary }}
    >
      <div className="contacts--container">
        <h1 style={{ color: theme.primary }}>Get In Touch</h1>
      </div>

      <div className="contacts--container">
        <div className="contacts-details">
          <a href={`mailto:${contactsData.email}`} className="personal-details">
            <div className={classes.detailsIcon}>
              <FiAtSign />
            </div>
            <p style={{ color: theme.tertiary }}>{contactsData.email}</p>
          </a>
          <div className="personal-details">
            <div className={classes.detailsIcon}>
              <HiOutlineLocationMarker />
            </div>
            <p style={{ color: theme.tertiary }}>{contactsData.address}</p>
          </div>

          <div className="socialmedia-icons">
            {socialsData.twitter && (
              <a
                href={socialsData.twitter}
                target="_blank"
                rel="noreferrer"
                className={classes.socialIcon}
              >
                <FaTwitter aria-label="Twitter" />
              </a>
            )}
            {socialsData.github && (
              <a
                href={socialsData.github}
                target="_blank"
                rel="noreferrer"
                className={classes.socialIcon}
              >
                <FaGithub aria-label="GitHub" />
              </a>
            )}
            {socialsData.linkedIn && (
              <a
                href={socialsData.linkedIn}
                target="_blank"
                rel="noreferrer"
                className={classes.socialIcon}
              >
                <FaLinkedinIn aria-label="LinkedIn" />
              </a>
            )}
            {socialsData.instagram && (
              <a
                href={socialsData.instagram}
                target="_blank"
                rel="noreferrer"
                className={classes.socialIcon}
              >
                <FaInstagram aria-label="Instagram" />
              </a>
            )}
          </div>
        </div>
        <div>
          <img className="contactsImg" src={footer} alt="contacts" />
        </div>
      </div>
    </div>
  );
}

export default Contacts;
