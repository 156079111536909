import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { ThemeContext } from "../../contexts/ThemeContext";
import "./Services.css";

function Services() {
  const { theme } = useContext(ThemeContext);
  const useStyles = makeStyles((t) => ({
    resumeBtn: {
      color: theme.primary,
      borderRadius: "30px",
      textTransform: "inherit",
      textDecoration: "none",
      width: "150px",
      fontSize: "1rem",
      fontWeight: "500",
      height: "50px",
      fontFamily: "var(--primaryFont)",
      border: `3px solid ${theme.primary}`,
      transition: "100ms ease-out",
      "&:hover": {
        backgroundColor: theme.tertiary,
        color: theme.secondary,
        border: `3px solid ${theme.tertiary}`,
      },
      [t.breakpoints.down("sm")]: {
        width: "180px",
      },
    },
  }));

  const classes = useStyles();
  return (
    <>
      <div
        className="services"
        id="services"
        style={{ backgroundColor: theme.secondary }}
      >
        <div className="services-header">
          <h1 style={{ color: theme.primary }}>Let's collaborate together</h1>
        </div>
        <div className="services-body">
          <p style={{ color: theme.tertiary80 }}>
            To add value and recognition to the workplace by achieving the
            greatest possible outcome for the company via synchronisation and
            hard work.
          </p>
          <NavLink to="/#contacts" smooth={true} spy="true" duration={2000}>
            <Button className={classes.resumeBtn}>Let's Connect</Button>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Services;
