export const skillsData = [
  "HTML",
  "CSS",
  "Javascript",
  "React",
  "Next JS",
  "Python",
  "Firebase",
  "Bootstrap",
  "Typescript",
  "nodejs",
  "MongoDB",
  "Tailwind",
  "MySQL",
  "PostgreSQL",
];
