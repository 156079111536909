export const toolsData = [
  "Figma",
  "Illustrator",
  "Adobe XD",
  "Canva",
  "Git",
  "photoshop",
  "vscode",
  "pycharm",
];
